<template>
  <div class="bg-gray-50 dark:bg-gray-900">
    <div class="flex items-start min-h-screen">
      <div class="my-28 container max-w-6xl px-5 mx-auto">
        <div class="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
          <div class="text-3xl text-gray-800 font-black">商店</div>
          <div class="flex flex-wrap items-center justify-center">
            <div
              class="flex-shrink-0 m-6 relative overflow-hidden bg-purple-500 rounded-lg max-w-xs shadow-lg"
            >
              <svg
                class="absolute bottom-0 left-0 mb-8"
                viewBox="0 0 375 283"
                fill="none"
                style="transform: scale(1.5); opacity: 0.1"
              >
                <rect
                  x="159.52"
                  y="175"
                  width="152"
                  height="152"
                  rx="8"
                  transform="rotate(-45 159.52 175)"
                  fill="white"
                />
                <rect
                  y="107.48"
                  width="152"
                  height="152"
                  rx="8"
                  transform="rotate(-45 0 107.48)"
                  fill="white"
                />
              </svg>
              <div
                class="relative pt-10 px-10 flex items-center justify-center"
              >
                <div
                  class="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                  style="
                    background: radial-gradient(black, transparent 60%);
                    transform: rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1);
                    opacity: 0.2;
                  "
                ></div>
                <img
                  class="relative w-40"
                  src="https://i.loli.net/2021/03/12/XOFr5YZbtfLGvQR.png"
                  alt=""
                />
              </div>
              <div class="relative text-white px-6 pb-6 mt-6">
                <span class="block opacity-75 -mb-1">White Edition</span>
                <div class="flex justify-between">
                  <span class="block font-semibold text-xl">Justap</span>
                  <span
                    class="block bg-white rounded-full text-purple-500 text-xs font-bold px-3 py-2 leading-none flex items-center"
                    >$ 19.99</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item to="/shop" icon="gift-o">商店</van-tabbar-item>
      <van-tabbar-item to="/anlytic" icon="chart-trending-o"
        >分析</van-tabbar-item
      >
      <van-tabbar-item
        to="/me"
        :icon="avatar + '?x-oss-process=image/rounded-corners,r_4096'"
        >我</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
import { userinfo } from "@/api/store";
// let _this = this
export default {
  name: "Anlytic",
  data() {
    return {
      active: 0,
      avatar: "",
      profile: {},
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // 请求信息
      userinfo().then((response) => {
        this.profile = response.data.data;
        this.avatar = response.data.data.avatar;
        console.log(response.data.data);
      });
    },
  },
};
</script>